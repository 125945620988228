// Here you can add other styles

.hc-block {
  display: flex;
  justify-content: center;
}

.send-message-button {
  width: 300px;
  margin: 10px;
}

.password-control {
  position: absolute;
  top: 143px;
  right: 21px;
  display: inline-block;
  width: 20px;
  height: 20px;
  background: url(../assets/images/password-view.svg) 0 0 no-repeat;
}

.password-control.view {
  background: url(../assets/images/password-no-view.svg) 0 0 no-repeat;
}